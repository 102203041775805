export const services = {
    smalltexts: {
        "custom_web_development": "Custom web development",
        "custom_web_development_description": "We build top-tier websites for businesses and organizations with rigorous custom requirements.",
        "mobile_applications": "Mobile applications",
        "mobile_applications_description": "We support organizations looking to find innovative ways to connect with their audience using iOS and Android apps.",
        "admin_interfaces": "Admin interfaces",
        "admin_interfaces_description": "We help enhance your workflows by building tailor-made intranet and extranet interfaces.",
        "automation": "Automation",
        "automation_description": "Experience unparalleled efficiency with cutting-edge automation services that are designed to revolutionize your business operations.",
        "vr_ar_experiences": "VR/AR Experiences",
        "vr_ar_experiences_description": "We help elevate your brand by crafting immersive VR/AR experiences that captivate audiences and redefine engagement.",
        "cloud_infrastructure": "Cloud infrastructure (CI/CD)",
        "cloud_infrastructure_description": "Optimize your development operations with robust Cloud infrastructures and CI/CD pipelines that provide continuous integrations and rapid deployments.",
        "browse_all_services": "Browse all services",
        "drupal_development": "Drupal development",
        "drupal_description": "Elevate your digital assets with Drupal, an enterprise-grade CMS tailored to deliver powerful, flexible, and scalable digital solutions.",
        "craft_cms_development": "Craft CMS development",
        "craft_cms_description": "Boost your online presence with Craft CMS, a user-friendly, scalable, and high-performance alternative to WordPress.",
        "design_creative": "Design & Creative",
        "design_creative_description": "We build data-driven visuals and colorful designs that positively increase your brand recognition.",
        "we_focus_on_results": "We focus on results.",
        "focus_results_description": "We specialize in complex architectures and intricate solutions for businesses and organizations with rigorous requirements.<br/><br/>We aim to deliver a seamless experience and extraordinary results.",
        "email_journeys": "Email journeys",
        "email_journeys_description": "Elevate your marketing campaigns with automated emails that are meticulously designed to captivate your audience and drive engagement.",
        "optimizely": "Optimizely Development",
        "optimizely_description": "Elevate your online presence with Optimizely CMS, a leading experimentation platform that helps you deliver better customer experiences.",
        "ai": "Artificial Intelligence",
        "ai_description": "Enhance your operations with AI-driven solutions that are designed to streamline your processes and drive efficiency.",
    },
    method: "Rollin delivers exceptional results through our proven process: deep client collaboration, data-driven strategy, and relentless execution. With hundreds of successful engagements, <strong>we don't just meet expectations—we transform them into lasting business advantages.</strong>",
    contact: {
        "design_title": "Need a creative partner?",
        "customweb_title": "Need custom web development?",
        "drupal_title": "Looking for Drupal experts?",
        "craft_title": "Need CraftCMS professionals?",
        "admin_title": "Looking to build an interface?",
        "mobile_title": "Thinking of building an app?",
        "automation_title": "Looking to automate your processes?",
        "cloud_title": "Need Cloud expertise?",
        "vr_title": "Looking to build a VR experience?",
        "email_title": "Need to build email series?",
        "contact_text": "Fill the form to tell us about your project, our team will get back to you quickly.",
        "message": "Message",
        "optimizely_title": "Ready to elevate your presence with Optimizely?",
        "ai_title": "Ready to enhance your operations with AI?",
    }
}
