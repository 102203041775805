export const services = {
    smalltexts: {
        "custom_web_development": "Développement web personnalisé",
        "custom_web_development_description": "Nous bâtissons des sites web pour les entreprises et les organisations ayant des exigences pointues et personnalisées.",
        "mobile_applications": "Applications mobiles",
        "mobile_applications_description": "Nous supportons les organisations qui cherchent à trouver des moyens innovants de connecter avec leur public à l'aide d'applications mobiles iOS et Android.",
        "admin_interfaces": "Interfaces d'administration",
        "admin_interfaces_description": "Nous aidons à améliorer vos processus de travail en développant des interfaces intranet et extranet sur mesure.",
        "automation": "Automatisation",
        "automation_description": "Faites l'expérience d'une efficacité inégalée avec des services d'automatisation conçus pour révolutionner les opérations de votre entreprise.",
        "vr_ar_experiences": "Expériences VR/AR",
        "vr_ar_experiences_description": "Nous aidons à propulser votre image en créant des expériences VR/AR immersives qui captivent le public et redéfinissent l'engagement.",
        "cloud_infrastructure": "Infrastructure cloud (CI/CD)",
        "cloud_infrastructure_description": "Optimisez vos opérations de développement avec des infrastructures cloud robustes et des pipelines CI/CD qui favorisent des intégrations continues.",
        "browse_all_services": "Consulter les services",
        "drupal_development": "Développement Drupal",
        "drupal_description": "Améliorez vos actifs numériques avec Drupal, un CMS de niveau entreprise conçu pour fournir des solutions numériques puissantes, flexibles et évolutives.",
        "craft_cms_development": "Développement Craft CMS",
        "craft_cms_description": "Améliorez votre présence en ligne avec Craft CMS, une alternative performante, conviviale et évolutive à WordPress.",
        "design_creative": "Design & Créatif",
        "design_creative_description": "Nous bâtissons des visuels dynamiques et des designs colorés qui augmentent positivement la reconnaissance de votre marque.",
        "we_focus_on_results": "Nous nous concentrons sur les résultats.",
        "focus_results_description": "Nous nous spécialisons dans les architectures complexes et les solutions élaborées pour les entreprises et les organisations ayant des exigences rigoureuses.<br/><br/>Nous visons à offrir une expérience fluide et des résultats extraordinaires.",
        "email_journeys": "Parcours e-mail",
        "email_journeys_description": "Améliorez vos campagnes marketing avec des e-mails automatisés soigneusement conçus pour captiver votre audience et stimuler l'engagement.",
        "optimizely": "Développement Optimizely",
        "optimizely_description": "Renforcez votre présence en ligne avec Optimizely CMS, une plateforme d'expérimentation d'avant-garde qui vous aide à offrir de meilleures expériences client.",
        "ai": "Intelligence Artificielle",
        "ai_description": "Optimisez vos opérations avec des solutions basées sur l'IA, conçues pour rationaliser vos processus d'affaires et accroître votre efficacité.",
    },
    method: "Rollin produit des résultats exceptionnels grâce à notre processus éprouvé : une collaboration étroite avec nos clients, une stratégie basée sur les données, et une exécution sans relâche. Avec des centaines de projets réussies, nous ne nous contentons pas de répondre aux attentes—nous les transformons en avantages commerciaux durables.",
    contact: {
        "design_title": "Besoin d'un partenaire créatif?",
        "customweb_title": "Besoin de développement web sur mesure?",
        "drupal_title": "Vous recherchez des experts Drupal?",
        "craft_title": "Besoin de professionnels CraftCMS?",
        "admin_title": "Vous souhaitez construire une interface?",
        "mobile_title": "Besoin de construire une application mobile?",
        "automation_title": "Vous cherchez à automatiser vos processus?",
        "cloud_title": "Besoin d'expertise Cloud?",
        "vr_title": "Vous cherchez à construire une expérience VR?",
        "email_title": "Besoin de bâtir vos courriels?",
        "contact_text": "Remplissez le formulaire pour nous parler de votre projet, nous vous répondrons dans les plus brefs délais.",
        "message": "Message",
        "optimizely_title": "Prêt à renforcer votre présence avec Optimizely ?",
        "ai_title": "Prêt à améliorer vos opérations avec l'IA ?",
    }
}
