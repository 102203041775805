export const home = {
    hero: {
        "group-title": "Nous sommes un petit groupe de développeurs",
        "group-word": "séniors",
        "group-word2": "",
        "group-title_2": "L'excellence",
        "group-word_2": "digitale",
        "group-word2_2": "à votre service.",
        "help-businesses": "Nous aidons les entreprises, les start-ups et les entités gouvernementales à surmonter leurs défis techniques en construisant des applications, des sites web et des interfaces exceptionnelles.",
        "get-started": "Commencer",
        "our-services": "Nos services",
        notificationHeader1: "Christine a accepté le devis",
        notificationHeader2: "Daniel a publié un nouveau contenu",
        notificationHeader3: "Luciana a reçu 79 commandes",
        "notification1": "Un nouveau parcours a été créé et une facture a été générée.",
        "notification2": "Daniel a publié un nouveau cours en attente d'approbation.",
        "notification3": "79 nouvelles commandes pour le cours \"Optimisez votre maîtrise du MBTI\".",
        "timestamp1": "Il y a 12 minutes",
        "timestamp2": "à l'instant",
        "timestamp3": "hier à 19h21",
        "response1": "Chemin de cours créé",
        "response2": "Facture programmée",
        "response3": "Nouveau contenu publié",
        "response4": "Un e-mail a été envoyé au gestionnaire",
        "response5": "Commandes traitées avec succès",
        "response6": "Sauvegarde lancée avec succès",
        "process-text": "Notre processus",
        "vision-planning": {
            "title": "Vision & planification",
            "description": "Votre succès repose sur une stratégie claire et des objectifs SMART.",
            "description2": "Nous planifions rigoureusement des applications à la fois complexes et intuitives qui sauront rejoindre vos utilisateurs."
        },
        "design-development": {
            "title": "Conception & développement",
            "description": "Nous connaissons bien vos enjeux techniques et savons comment vous aider à les surpasser.",
            "description2": "Notre objectif est de vous fournir des solutions de développement ingénieuses qui assureront votre succès numérique."
        },
        "maintenance-evolution": {
            "title": "Maintenance & évolution",
            "description": "Notre équipe s'assure de garder vos actifs numériques sécurisés et à jour.",
            "description2": "Nous vous proposons une approche fiable de maintenance et d'améliorations en continu de votre écosystème digital."
        },
        "browse-services": "Parcourez nos services",
        "creative-services": {
            "title": "Nous offrons des services créatifs aux moyennes et grandes entreprises à la recherche",
            "title-word": "d'expertise et d'excellence",
            "description": "Laissez-nous le soin de vous fournir des solutions simples et élégantes à vos problèmes de TI complexes. Notre processus vous permet de sécuriser des actifs numériques haut-de-gamme et évolutifs."
        }
    },
    about: {
        "we_are_lucky": "Nous avons la chance de travailler avec",
        "amazing_clients": "des clients incroyables",
        "and_well_established_partners": "et des partenaires bien établis",
        "we_work_closely": "Nous travaillons en étroite collaboration avec",
        "high_end_marketing_agencies": "des agences de marketing reconnues",
        "in_Canada": "au Canada.",
        "we_help_them_create": "Nous les aidons à créer",
        "powerful_digital_campaigns": "des campagnes numériques puissantes",
        "biggest_brands_world": "pour certaines des plus grandes marques au monde",
        "about_us": "Ce que les gens disent",
        "about_us2": "à propos de nous."
    },
    "process": {
        "deliverables": "Livrables",
        "step1": "Découverte",
        "step1_text": "Nous collaborons étroitement avec les parties prenantes pour comprendre les exigences et définir les critères de réussite. Notre processus comprend l'étude de votre public cible, l'identification des objectifs organisationnels et l'établissement d'indicateurs clés de performance (KPI).",
        "step1_1": "Entretiens avec les parties prenantes",
        "step1_2": "Documentation",
        "step1_3": "Objectifs et KPI",
        "step2": "Audit",
        "step2_text": "Nous évaluons minutieusement vos actifs numériques, analysons le contenu, la voix de la marque et l'infrastructure technique. Notre équipe identifie les améliorations potentielles et assure l'alignement avec vos objectifs organisationnels. Ce processus fournit des indices qui informent et guident la phase d'exécution.",
        "step2_1": "Analyse de l'infrastructure",
        "step2_2": "Évaluation des performances",
        "step2_3": "Rapport d'audit",
        "step3": "Prototypage",
        "step3_text": "Nous créons des représentations visuelles de la structure et de la mise en page de votre projet, en cartographiant les parcours utilisateurs et les fonctionnalités principales. Ce plan établit les bases du développement et assure la clarté et l'alignement avant que nous n'écrivions une seule ligne de code.",
        "step3_1": "Maquettes filaires",
        "step3_2": "Parcours comportementaux",
        "step3_3": "Conceptions UI/UX",
        "step4": "Développement",
        "step4_text": "Notre équipe donne vie au design en écrivant un code propre, efficace et testable. Nous nous concentrons sur les paradigmes de programmation modernes, les architectures évolutives et les solutions intuitives qui dépassent les attentes.",
        "step4_1": "Accessibilité",
        "step4_2": "Backend sécurisé",
        "step4_3": "Intégrations API",
        "step5": "QA et Livraison",
        "step5_text": "Notre équipe de QA effectue des tests unitaires méticuleux et surveille les composants critiques du système pour assurer une performance optimale. Par la suite, nos spécialistes du déploiement gèrent les phases finales de mise en œuvre et orchestrent une transition fluide pour un lancement réussi.",
        "step5_1": "Optimisation des performances",
        "step5_2": "Déploiement CI/CD",
        "step5_3": "Rapport d'assurance qualité",
        "step5_4": "Certificat de garantie"
    }
}
