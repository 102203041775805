export const ai = {
    "title1": "Artificial Intelligence",
    "text1": "AI has evolved from a business advantage to a business necessity—it's an essential force multiplier. We've moved beyond the era of basic automation into a space where AI actively contributes to business growth and innovation. <br><br>By combining technical expertise with analytical insight, we develop AI solutions that solve intricate challenges and delivers quantifiable results. Our methodology turns artificial intelligence from a promising technology into a powerful business asset that drives real value for your organization.",
    "title2": "We deliver a <span>comprehensive suite of AI solutions</span> that work in harmony. Each solution is <span>meticulously crafted to your specific workflows</span>, yielding consistent, intelligent interactions that adapt to user needs while reducing operational demands.",
    "text2": "Our custom AI assistants serve as intelligent agents that embody your brand's voice, while our sophisticated chatbots handle complex conversations across your digital platforms. Through advanced voice integration, we extend these capabilities to natural speech interfaces, creating a unified AI presence that engages with users through their preferred medium—be it text, chat, or voice.",
    "title3": "Our expertise delivers solutions <span>that anticipate tomorrow's challenges</span>. Let us help you explore how you can put AI to work for your organization.",
    "bullet1": "<span class='big text-5xl bold block'>250+</span>emails campaigns",
    "bullet2": "<span class='big text-5xl bold block'>75+</span>automated scenarios",
    "bullet3": "<span class='big text-5xl bold block'>15 years</span>in business",
    "bullet4": "<span class='big text-5xl bold block'>12</span>developers <br> and designers",
}