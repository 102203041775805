export const ai = {
    "title1": "Intelligence Artificielle",
    "text1": "L'IA est passée d'un avantage commercial à une nécessité commerciale—c'est un multiplicateur de force essentiel. Nous avons dépassé l'ère de l'automatisation basique pour entrer dans un espace où l'IA contribue activement à la croissance et à l'innovation des entreprises. <br><br>Notre méthodologie transforme l'intelligence artificielle d'une technologie prometteuse en un puissant atout commercial qui génère une valeur réelle pour votre organisation.",
    "title2": "Nous proposons une <span>suite complète de solutions d'IA</span> qui fonctionnent en harmonie. Chaque solution est <span>méticuleusement adaptée à vos flux de travail spécifiques</span>, produisant des interactions intelligentes et cohérentes qui s'adaptent aux besoins des utilisateurs tout en réduisant les exigences opérationnelles.",
    "text2": "Nos assistants IA personnalisés agissent comme des agents intelligents qui incarnent la voix de votre marque, tandis que nos chatbots sophistiqués gèrent des conversations complexes sur vos plateformes numériques. Grâce à l'intégration vocale avancée, nous étendons ces capacités aux interfaces vocales naturelles, créant une présence IA unifiée qui interagit avec les utilisateurs via leur médium préféré—que ce soit le texte, le chat ou la voix.",
    "title3": "Notre expertise fournit des solutions <span>qui anticipent les défis de demain</span>. Laissez-nous vous aider à explorer comment mettre l'IA au service de votre organisation.",
    "bullet1": "<span class='big text-5xl bold block'>250+</span>campagnes emails",
    "bullet2": "<span class='big text-5xl bold block'>75+</span>scénarios automatisés",
    "bullet3": "<span class='big text-5xl bold block'>15 ans</span>d'expérience",
    "bullet4": "<span class='big text-5xl bold block'>12</span>développeurs <br> et designers",
}